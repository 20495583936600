import styled from "styled-components"
import { styles } from "../utils"

export const Section = styled.section`
  padding: 2rem 0;
  width: 90vw;
  margin: 0 auto;
  background-color: ${styles.colors.mainBlue};
`
export const QuickInfoWrapper = styled.div`
  background-color: ${styles.colors.mainBlue};
  width: 90%;
  margin: 2rem auto;
  .text {
    font-size: 1rem;
    line-height: 1.8rem;
    color: ${styles.colors.mainPink};
    word-spacing: 0.5rem;
  }
  .controllerText {
    font-size: 0.7rem;
    line-height: 2.5rem;
    color: ${styles.colors.mainWhite};
  }
  @media (min-width: 768px) {
    width: 70%;
  }
  @media (min-width: 992px) {
    width: 60%;
  }
`
