import styled from "styled-components"
import { styles } from "../utils"

const BannerButton = styled.button`
  display: center;
  color: ${styles.colors.mainWhite};
  background: transparent;
  margin-top: -30px;
  padding: 1rem 1rem 1rem 1.5rem;
  font-size: 1.5rem;
  letter-spacing: 0.5rem;
  font-weight: 700;
  ${styles.border({ color: `${styles.colors.mainWhite}` })};
  margin-bottom: 1rem;
  ${styles.transition({})};
  &:hover {
    background: ${styles.colors.mainPink};
    color: ${styles.colors.mainBlack};
    cursor: pointer;
  }
`
const BannerImage = styled.div`
  &:hover {
    opacity: 0.8;
    border: 8px solid palevioletred;
    border-radius: 8px;
    cursor: pointer;
  }
`
const SectionButton = styled(BannerButton)`
  font-weight: 700;
  padding: 1rem 1rem 1rem 1.5rem;
  display: center;
  color: ${styles.colors.mainWhite};
  ${styles.border({ color: `${styles.colors.mainWhite}` })};
  &:hover {
    background: ${styles.colors.mainPink};
    color: ${styles.colors.mainBlack};
  }
`
const BannerButton1 = styled.button`
  display: center;
  color: ${styles.colors.mainWhite};
  background: transparent;
  margin-top: -30px;
  padding: 1rem 1rem 1rem 1.5rem;
  font-size: 1rem;
  letter-spacing: 0rem;
  font-weight: 700;
  ${styles.border({ color: `${styles.colors.mainWhite}` })};
  margin-bottom: 1rem;
  ${styles.transition({})};
  &:hover {
    background: ${styles.colors.mainPink};
    color: ${styles.colors.mainBlack};
    cursor: pointer;
  }
`
export { BannerButton, SectionButton, BannerImage, BannerButton1 }
