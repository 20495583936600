/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { createGlobalStyle } from "styled-components"
import Navbar from "../components/globals/navbar"
import Footer from "../components/globals/Footer"
import CookieConsent from "react-cookie-consent"
import "./layout.css"
const Layout = ({ children }) => {
  return (
    <React.Fragment>
      <GlobalStyle />
      <Navbar />
      {children}
      <Footer />
      <CookieConsent location="bottom" buttonText="Accept" cookieName="ga">
        We use cookies to analyse our traffic and save your game progress...
      </CookieConsent>
    </React.Fragment>
  )
}
const GlobalStyle = createGlobalStyle`
*{margin:0;
padding:0;
box-sizing:border-box;

}
body{
  font-family:'Press Start 2P', 'cursive';
  color:#262626;
  background:#161823;
}
`
Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
