import React, { Component } from "react"
import styled from "styled-components"
import { styles } from "../../../utils"
import { FaTwitter, FaYoutube } from "react-icons/fa"

export default class NavbarIcons extends Component {
  state = {
    icons: [
      {
        id: 0,
        icon: <FaTwitter className="icon twitter-icon" />,
        path: "https://twitter.com/eddynardo11",
      },
      {
        id: 1,
        icon: <FaYoutube className="icon youtube-icon" />,
        path: "https://www.youtube.com/channel/UCsXJ9GxAlNICSdGoqlVsjHQ",
      },
    ],
  }
  render() {
    return (
      <IconWrapper>
        {this.state.icons.map(item => {
          return (
            <a
              href={item.path}
              key={item.id}
              target="_blank"
              rel="noopener noreferrer"
            >
              {item.icon}{" "}
            </a>
          )
        })}
      </IconWrapper>
    )
  }
}

const IconWrapper = styled.div`
  background: ${styles.colors.mainBlue};
  .icon {
    font-size: 1.3rem;
    cursor: pointer;
    ${styles.transFunction()};
  }

  .twitter-icon {
    color: #3ab7f0;
  }
  .youtube-icon {
    color: #c4302b;
  }

  .icon:hover {
    color: ${styles.colors.subPink};
  }
  display: none;
  @media (min-width: 768px) {
    display: flex;
    width: 6rem;
    justify-content: space-around;
  }
`
