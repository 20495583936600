import React from "react"
import styled from "styled-components"
function HomeHeader({ img, children }) {
  return <IndexHeader img={img}>{children}</IndexHeader>
}

function PageHeader({ img, children }) {
  return <DefaultHeader img={img}>{children}</DefaultHeader>
}
function ContactHeader({ img, children }) {
  return <BlankHeader img={img}>{children}</BlankHeader>
}
function BannerHeader({ img, children }) {
  return <BannHeader img={img}>{children}</BannHeader>
}
const IndexHeader = styled.header`
  min-height: calc(100vh);
  image-rendering: auto;
  image-rendering: crisp-edges;
  image-rendering: pixelated;

  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)),
    url(${props => props.img}) center/cover fixed no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
`
const MainHeader = styled.header`
  min-height: calc(100vh);
  image-rendering: auto;
  image-rendering: crisp-edges;
  image-rendering: pixelated;

  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)),
    url(${props => props.img}) center/cover fixed no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
`
const DefaultHeader = styled(IndexHeader)`
  min-height: calc(100vh - 180px);
`
const BlankHeader = styled(IndexHeader)`
  min-height: calc(100vh - 447.8px);
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)),
    url(${props => props.img}) center/cover no-repeat;
`
const BannHeader = styled(IndexHeader)`
  min-height: calc(100vh - 78vh);
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)),
    url(${props => props.img}) center/contain no-repeat;
`
const ChopyHeader = styled(MainHeader)`
  min-height: calc(100vh - 600px);
  background: white, url(${props => props.img}) center/contain no-repeat;
`
export { HomeHeader, PageHeader, ContactHeader, BannerHeader, ChopyHeader }
